/**
 * OS及びブラウザを判定し、Bodyのクラスに追加
 */
import { functions } from './functions';

export module DeviceCheckModule {
  export class Service {
    constructor(language?: string) {
      document.addEventListener('readCompleteAction', () => {
        Service.addClass();
      });
    }

    /**
     * クラス追加
     */
    public static addClass() {
      const $os: string = functions.getOS();
      const $browser: string = functions.getBrowser();
      const $body = document.body;

      if ($os) {
        if (!$body.classList.contains('is__' + $os)) {
          $body.classList.add('is__' + $os);
        }
      }

      if ($browser) {
        if (!$body.classList.contains('is__' + $browser)) {
          $body.classList.add('is__' + $browser);
        }
      }

      if (functions.isTouchDevice()) {
        if (!$body.classList.contains('is__touchDevice')) {
          $body.classList.add('is__touchDevice');
        }
      }
    }

  }
}