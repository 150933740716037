export module LoadModule {
  export class Service {
    private static BODY: Element = document.getElementsByTagName('body')[0];

    constructor(language?: string) {
      document.addEventListener('DOMContentLoaded', function () {
        if (!Service.BODY.classList.contains('ready-complete')) {
          let $EVENT;
          try {
            $EVENT = new Event('readCompleteAction');
          } catch (e) {
            $EVENT = document.createEvent('Event');
            $EVENT.initEvent('readCompleteAction', false, true);
          }

          Service.BODY.classList.add('ready-complete');
          document.dispatchEvent($EVENT);
        }
      }, false);

      window.addEventListener("load", function () {
        if (!Service.BODY.classList.contains('ready-complete')) {
          let $EVENT;
          try {
            $EVENT = new Event('readCompleteAction');
          } catch (e) {
            $EVENT = document.createEvent('Event');
            $EVENT.initEvent('readCompleteAction', false, true);
          }

          Service.BODY.classList.add('ready-complete');
          document.dispatchEvent($EVENT);
        }
        if (!Service.BODY.classList.contains('load-complete')) {
          let $EVENT;
          try {
            $EVENT = new Event('loadCompleteAction');
          } catch (e) {
            $EVENT = document.createEvent('Event');
            $EVENT.initEvent('loadCompleteAction', false, true);
          }

          Service.BODY.classList.add('load-complete');
          document.dispatchEvent($EVENT);
        }
      });
    }
  }
}
