export module ToggleActionModule {
  export class Service {
    constructor(language?: string) {
      document.addEventListener('readCompleteAction', () => {
        Service.toggleAction();
      });
    }

    /**
     * トグル処理
     */
    public static toggleAction() {
      let $elements = document.getElementsByClassName('js__toggle');
      if ($elements && $elements.length) {
        for (let $i = 0; $i < $elements.length; $i++) {
          let $element = $elements[$i];
          $element.addEventListener('click', () => {
            let $target: string | null = $element.getAttribute('data-target');
            let $toggleclass: string | null = $element.getAttribute('data-toggleclass');
            if ($target && $toggleclass) {
              let $targetElement = document.querySelector($target);
              if ($targetElement) {
                $targetElement.classList.toggle($toggleclass);
              }
            }
          });
        }
      }
    }
  }
}