import { Offset } from './offset';

export module SmoothScrollModule {
  const EaseFunction = {
    linear: (t: number) => t,
    easeInOut: (t: number) => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    easeInQuad: (t: number) => t * t,
    easeOutQuad: (t: number) => t * (2 - t),
    easeInOutQuad: (t: number) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t,
    easeInCubic: (t: number) => t * t * t,
    easeOutCubic: (t: number) => (--t) * t * t + 1,
    easeInOutCubic: (t: number) => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
    easeInQuart: (t: number) => t * t * t * t,
    easeOutQuart: (t: number) => 1 - (--t) * t * t * t,
    easeInOutQuart: (t: number) => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,
    easeInQuint: (t: number) => t * t * t * t * t,
    easeOutQuint: (t: number) => 1 + (--t) * t * t * t * t,
    easeInOutQuint: (t: number) => 1 + (--t) * t * t * t * t,
    easeInElastic: (t: number) => (.04 - .04 / t) * Math.sin(25 * t) + 1,
    easeOutElastic: (t: number) => .04 * t / (--t) * Math.sin(25 * t),
    easeInOutElastic: (t: number) => (t -= .5) < 0 ? (.01 + .01 / t) * Math.sin(50 * t) : (.02 - .01 / t) * Math.sin(50 * t) + 1,
  }

  export class Service {
    public static SPEED: number = 500;
    private static EASE = EaseFunction;

    constructor(language?: string) {
      document.addEventListener('loadCompleteAction', () => {
        // 別ページからのアンカーリンク
        Service.otherPageScroll();
      });

      document.addEventListener('readCompleteAction', () => {
        // ノースクロール
        Service.noneScroll();

        // スムーズスクロール
        Service.smoothScroll();
      });
    }

    /**
     * 別ページからのアンカーリンク
     */
    public static otherPageScroll() {
      let $urlHash = location.hash;
      let $targetElement = document.getElementById($urlHash.replace('#', ''));
      if ($urlHash && $targetElement) {
        let $offsetTop = window.pageYOffset + $targetElement.getBoundingClientRect().top;
        let $adminBar = document.getElementById('wpadminbar');
        if ($adminBar) {
          $offsetTop -= $adminBar.offsetHeight;
        }
        window.scrollTo({
          top: $offsetTop,
        });
      }
    }

    /**
     * ノースクロール
     */
    public static noneScroll() {
      let $elements = document.querySelectorAll('.noscroll');
      if ($elements && $elements.length) {
        $elements.forEach(($element) => {
          $element.addEventListener('click', ($event) => {
            $event.preventDefault();
          });
        });
      }
    }

    /**
     * スムーズスクロール
     */
    public static smoothScroll() {
      let $ankers = document.querySelectorAll('a[href^="#"]:not(.noscroll)');
      if ($ankers && $ankers.length) {
        $ankers.forEach(($anker) => {
          $anker.addEventListener('click', ($event) => {
            let $href: string | null = $anker.getAttribute('href');
            let $currentPostion: number = Service.bodyOrHtml().scrollTop;
            let $targetElement: HTMLElement | null;
            let $targetPosition: number;
            let $startTime: number;

            if ($href) {
              $targetElement = document.getElementById($href.replace('#', ''));
              if ($targetElement || '#' === $href) {
                $event.preventDefault();

                $startTime = performance.now();
                $targetPosition = ('#' === $href || null === $targetElement) ? 0 : Offset.getTop($targetElement);

                let loopFunction = function ($nowTime: number) {
                  let $time = $nowTime - $startTime;
                  let $normalizedTime = $time / Service.SPEED;
                  if ($normalizedTime < 1) {
                    window.scrollTo(0, $currentPostion + (($targetPosition - $currentPostion) * Service.EASE.easeInOut($normalizedTime)));
                    requestAnimationFrame(loopFunction);
                  } else {
                    window.scrollTo(0, $targetPosition);
                  }
                }

                requestAnimationFrame(loopFunction);
              }

              return false;
            }
          });
        });
      }
    }

    private static bodyOrHtml() {
      let $return: Element | null;
      if ('scrollingElement' in document) {
        $return = document.scrollingElement;
      }
      if (navigator.userAgent.indexOf('WebKit') != -1) {
        $return = document.body;
      }
      $return = document.documentElement;
      return $return;
    }
  }
}
