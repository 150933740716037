export namespace Offset {
  export function getTop($element: HTMLElement) {
    let $rect = $element.getBoundingClientRect();
    let $scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return $rect.top + $scrollTop;
  }
  export function geRright($element: HTMLElement) {
    let $left = Offset.getLeft($element);
    return $left + $element.offsetWidth;
  }

  export function geBottom($element: HTMLElement) {
    let $top = Offset.getTop($element);
    return $top + $element.offsetHeight;
  }

  export function getLeft($element: HTMLElement) {
    let $rect = $element.getBoundingClientRect();
    let $scrollTop = window.pageXOffset || document.documentElement.scrollLeft;
    return $rect.left + $scrollTop;
  }
}