/**
 * デバイスチェック
 */
import { DeviceCheckModule } from './modules/deviceCheck';
const $deviceCheckModule = new DeviceCheckModule.Service();

/**
 * スマホ100vh対策
 */
import { ViewportModule } from './modules/viewport';
const $viewport = new ViewportModule.Service();

/**
 * スムーズスクロール
 * 別ページからのアンカーリンク
 */
import { SmoothScrollModule } from './modules/smooth_scroll';
const $smoothScrollModule = new SmoothScrollModule.Service();

/**
 * スクロールイベント
 * 「.js__scroll」に対して処理をする
 */
import { ScrollEventModule } from './modules/scroll_event';
const $scrollEventModule = new ScrollEventModule.Service();

/**
 * トグルイベント
 * 「.js__toggle」に対して処理をする
 * data-target data-toggleclass の設定が必要
 */
import { ToggleActionModule } from './modules/toggle_action';
const $toggleActionModule = new ToggleActionModule.Service();

/**
 * 読み込み完了
 */
import { LoadModule } from './modules/load';
const $load = new LoadModule.Service();
